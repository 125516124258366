@use '../../../../../../styles';

.activity-header-title {
    display: flex;
    align-items: center;
    gap: 10px;

    &__text {
        color: styles.$variables-dark-blue-2;
        @include styles.mixins-style-font-format(18px, normal, 700);
      }

      &__bell.ant-btn {
        background-color: styles.$variables-light-blue-2;
        path {
          fill: styles.$variables-dark-blue-2;
          @include styles.mixins-style-width-height(32px, 32px);
          cursor: pointer;
        }

        &:hover{
          background-color: styles.$variables-light-blue-3;
        }
      }
}
