@use '../../../styles';

.filters-with-icons-container {
  @include styles.mixins-style-flex-row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  display: flex;
  width: 100%;

  .filters-overview {
    width: 93%;

    &__filter-button {
      position: relative;
      height: 40px;

      .filters-dropdown {
        right: 0;
        top: 0;
      }
    }
  }
  .filters-tab-overview-container {
    @include styles.mixins-style-flex-row-space-between;
    gap: 20px;

    .filters-tab-overview {
      @include styles.mixins-style-flex-row-space-between;
      overflow-y: hidden;
      height: fit-content;

      &__filters-overview-tabs {
        @include styles.mixins-style-flex-row;
        width: 95%;
        gap: 36px;

        .filters-overview-tabs-container {
          display: flex;
          gap: 32px;

          &__tab {
            @include styles.mixins-style-font-format(14px, 16px, 700, capitalize);
            @include styles.mixins-style-flex-column;
            align-items: center;
            width: 100%;
            padding: 8px 10px;
            left: 285px;
            top: 122px;
            cursor: pointer;

            .tab-title {
              text-align: center
            }

            .sidebar-icon {
              padding: 8px;
              background-color: transparent;
              border-radius: 12px;
              gap: 8px;
              width: fit-content;
              margin-bottom: 8px;
            }

            .sidebar-icon svg {
              @include styles.mixins-style-width-height(24px, 24px);
              stroke: styles.$variables-dark-grey;
              color: styles.$variables-dark-grey;
            }
          }

          .active-tab {
            margin-bottom: 16px;
            border-bottom: 1px solid styles.$variables-primary-blue;
            width: 100%;

            .sidebar-icon {
              background-color: styles.$variables-primary-blue;
            }
            .sidebar-icon svg {
              @include styles.mixins-style-width-height(24px, 24px);
              stroke: styles.$variables-white;
              color: styles.$variables-white;
            }
          }
        }
      }
    }

    .button-container {
      .arrow-button-container {
        @include styles.mixins-style-width-height(fit-content, fit-content);
        align-items: center;
        margin-top: 20px;
        overflow: hidden;

        .arrow-button {
          @include styles.mixins-style-width-height(40px, 40px);
          @include styles.mixins-style-flex-start;
          border-radius: 50%;
          padding: 8px;
          gap: 8px;
          width: fit-content;

          .sidebar-icon svg {
            @include styles.mixins-style-width-height(24px, 24px);
            fill: styles.$variables-primary-grey;
          }
        }
      }
    }
  }
}
