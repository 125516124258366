@use '../../../../styles';

.dashboard-cards__container {
  @include styles.mixins-style-flex-row;
  gap: 30px;

  .dashboard-card {
    @include styles.mixins-style-flex-center-column;
    flex: 1 1;
    box-shadow: styles.$variables-vertical-box-shadow;
    gap: 16px;
    padding: 32px 16px;

    &.blue-card {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-dark-blue-stroke, 16px);
      @include styles.mixins-gradients-blue-gradient;

      .dashboard-card__title, .dashboard-card__count {
        color: styles.$variables-extended-dark-blue;
      }

      .dashboard-card__icon {
        background: styles.$variables-extended-dark-blue;
      }
    }

    &.green-card {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-green-stroke, 16px);
      @include styles.mixins-gradients-green-gradient;

      .dashboard-card__title, .dashboard-card__count {
        color: styles.$variables-extended-green;
      }

      .dashboard-card__icon {
        background: styles.$variables-extended-green;
      }
    }

    &.purple-card {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-purple-stroke, 16px);
      @include styles.mixins-gradients-purple-gradient;

      .dashboard-card__count, .dashboard-card__title {
        color: styles.$variables-extended-purple;
      }

      .dashboard-card__icon {
        background: styles.$variables-extended-purple;
      }
    }

    &__icon {
      @include styles.mixins-style-width-height(64px, 64px);
      @include styles.mixins-style-flex-center;
      border-radius: 20px;

      svg {
        @include styles.mixins-style-width-height(26.67px, 26.67px);
        color: styles.$variables-white;
      }
    }

    .secondary-button {
      box-shadow: styles.$variables-spread-shadow;
    }

    &__title {
      @include styles.mixins-style-font-format(18px, 24px, 700);
      margin: 0;
    }

    &__count {
      @include styles.mixins-style-font-format(42px, 48px, 900);
    }
  }
}
