@use '../../../styles';

.about {
  @include styles.mixins-style-flex-column;
  overflow: hidden;
  text-align: start;

  &__title {
    @include styles.mixins-style-font-format(18px, 24px, 700, capitalize);
    margin: 0;
    color: styles.$variables-dark-grey;
  }

  &__container {
    width: 100%;
  }

  &__card {
    justify-content: space-between;
    color: styles.$variables-dark-grey;
    @include styles.mixins-style-font-format(14px, 20px, 400);
    @include styles.mixins-style-capitalize-first-word-only;
    width: 100%;

    &-data {
      width: 50%;
      @include styles.mixins-style-flex-row-space-between
    }
  }

  &__header {
    @include styles.mixins-style-flex-row-space-between;
    margin-bottom: 20px;
  }

  &__details {
    margin-top: 10px;
    margin-bottom: 15px;

    .details_row {
      display: grid;
      grid-template-columns: 3fr 9fr;
      margin-top: 15px;

      h4{
        font-weight: 700;
      }
    }
  }
}
