@use '../../../styles';

.input {
  @include styles.mixins-style-width-height(100%, auto);

  .add-button-container {
    &__label {
      color: styles.$variables-dark-grey;
      @include styles.mixins-style-font-format(18px, 24px, bold, capitalize);
    }
  }
  .ant-form-item.form-item {
    margin: 0;

    .ant-form-item-label>label {
      height: auto;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: styles.$variables-extended-light-blue!important;
    color: styles.$variables-primary-blue!important;
  }

  .ant-select {
    &-multiple {
      .ant-select-selection-item {
        @include styles.mixins-style-flex-center;
        gap: 8px;
        border: none;
        border-radius: 14px;
        background: styles.$variables-extended-light-blue;
        height: 28px;
        padding: 6px 12px;

        &-content {
          @include styles.mixins-style-font-format(14px, 22px, 500);
          color: styles.$variables-primary-blue;
        }

        &-remove {
          color: styles.$variables-primary-blue;
        }
      }
    }
  }

  .ant-select-arrow {
    svg {
      @include styles.mixins-style-width-height(16px, 16px);
    }
  }

  .ant-input:not(.ant-input-borderless),
  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-upload.ant-upload-select.ant-upload-select-picture,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number.ant-input-number-in-form-item,
  .ant-input-number {
    &, &:not(.ant-input-affix-wrapper-disabled):hover {
      @include styles.mixins-style-width-height(100%, 40px);
      color: styles.$variables-dark-grey;
      @include styles.mixins-style-font-format(14px, 20px, 400);
    }

    &:focus {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 20px);
      background: styles.$variables-white;
      box-shadow: none;
    }

    &:not(.ant-input-disabled):not(:focus):not(.ant-picker-focused) {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
      background: styles.$variables-white;
    }

    &.ant-input-disabled {
      &, &:hover {
        @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-grey, 20px);
        background: styles.$variables-secondary-grey;
      }
    }
  }

  .ant-input-wrapper.ant-input-group {
    .ant-input-group-addon {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px 0 0 20px);
      background: styles.$variables-white;
    }

    input.ant-input:not(.ant-input-borderless):not(.ant-input-disabled):not(:focus):not(.ant-picker-focused).ant-input-lg {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 0 20px 20px 0, top bottom right);
    }
  }

  .ant-input-number-input-wrap {
    width: 100%;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background: styles.$variables-white;
    border-color: #ff4d4f !important;
  }

  .ant-picker.ant-picker-disabled {
    &, &:hover {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-grey, 20px);
      background: styles.$variables-secondary-grey;
    }

    .ant-picker-suffix {
      color: styles.$variables-primary-grey;
    }
  }

  .ant-picker {
    .ant-picker-suffix {
      svg {
        @include styles.mixins-style-width-height(16px, 16px);
      }
    }
  }

  .ant-picker.ant-picker-focused {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 20px);
    box-shadow: none;

    .ant-picker-suffix {
      color: styles.$variables-primary-blue;
    }
  }

  .ant-form-item-label label {
    @include styles.mixins-style-flex-row;
    gap: 8px;
  }


  .ant-input-prefix,
  span.ant-picker-suffix,
  .dragger-text,
  .ant-form-item-label label,
  .ant-form-item-label label .ant-form-item-tooltip,
  .ant-picker-input input,
  .ant-input-number-group-addon {
    color: styles.$variables-dark-grey;
    @include styles.mixins-style-font-format(14px, 20px, 400, capitalize);

    .ant-btn-icon-only {
      @include styles.mixins-style-flex-center;
      opacity: 0.7;
      color: styles.$variables-dark-grey;
    }
  }

  .ant-form-item-label {
    white-space: normal;
  }

  input.ant-input,
  input.ant-input-number-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  div.ant-picker {
    &::placeholder {
      color: styles.$variables-dark-grey;
      opacity: 0.7;
      @include styles.mixins-style-font-format(14px, 20px, 400);
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    margin: auto 0;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
    .ant-select-selector {
      background-color: styles.$variables-white;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector:not(.ant-input-disabled):not(:focus):not(.ant-picker-focused), .input .ant-input-number.ant-input-number-in-form-item:not(.ant-input-disabled):not(:focus):not(.ant-picker-focused) {
    height: auto;
    min-height: 40px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: auto;
  }

  .ant-input-number-group-wrapper {
    @include styles.mixins-style-width-height(100%, 40px);
  }

  .ant-input-number-group {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-tertiary-grey, 20px);
    background: styles.$variables-white;
    overflow: hidden;
    height: 100%;

    &-addon {
      border: none;
      background: transparent;
    }

    &:has(.ant-input-number-group-addon) {
      .ant-input-number.ant-input-number-in-form-item {
        border: none!important;
      }
    }

    .ant-input-number {
      border: none;
      height: 40px;
      @include styles.mixins-style-flex-row-v-center;
      @include styles.mixins-style-font-format(14px, 20px, 400);
    }
  }

  .ant-input-number {
    height: 40px;
    @include styles.mixins-style-flex-row-v-center;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  textarea.ant-input {
    resize: none;
    line-height: 30px;
    height: unset !important;
    padding: 10px;
    @include styles.mixins-style-font-format(12px, normal, 700);
  }

  .dragger.ant-upload.ant-upload-drag {
    @include styles.mixins-style-border(dashed, 1px, styles.$variables-tertiary-grey, 8px);
    @include styles.mixins-style-flex-center;
    @include styles.mixins-style-width-height(400px, auto);
    min-height: 80px;
    background: transparent;

    .ant-upload-btn {
      @include styles.mixins-style-width-height(100%, auto);
    }

    .dragger-text-container{
      @include styles.mixins-style-flex-column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .dragger-text {
        @include styles.mixins-style-font-format(14px, 22px);
        color: styles.$variables-dark-grey;
      }
    }

    &:hover {
      border-color: styles.$variables-dark-grey;
    }
  }

  .ant-upload-list-picture-card-container {
    .ant-upload-list-item {
      border-color: transparent;
      padding: 0;
    }
  }

  .ant-btn.ant-btn-default.upload-image,
  .ant-upload.ant-upload-select.ant-upload-select-picture-card,
  .ant-upload-list-picture-card-container {
    @include styles.mixins-style-width-height(250px, 140px);
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue, 8px);
    margin: 0;
  }

  .ant-upload {
    overflow: hidden;
  }

  .ant-btn.ant-btn-default.upload-image,
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    background: styles.$variables-white;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Removing strange border that appears on number input for unknown reason
  input[type=number] {
    -moz-appearance: textfield;
    border-top: none;
    border-left: none;
    border-right: none;

    &:hover {
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      outline: none !important;
    }
  }

  .ant-btn.ant-btn-default.upload-image {
    background-color: styles.$variables-extended-light-blue;
    @include styles.mixins-style-border(none);
    color: styles.$variables-dark-grey;
    font-size: 40px;
    @include styles.mixins-style-flex-center-column;
  }

  .form-item.ant-form-item {
    &.form-item-vertical {
      .ant-form-item-row {
        @include styles.mixins-style-flex-column;
        gap: 8px;
      }
      .ant-col.ant-form-item-control {
        @include styles.mixins-style-width-height(100%, auto);
        max-width: 100%;
      }

      .ant-col.ant-form-item-label {
        width: 100%;
      }
    }

    &:not(.form-item-vertical) {
      .ant-col.ant-form-item-control {
        @include styles.mixins-style-width-height(75%, auto);
        max-width: 75%;
      }

      .ant-col.ant-form-item-label {
        width: 25%;
      }

      .ant-form-item-row {
        align-items: center;
      }
    }

    .ant-row {
      .ant-col.ant-form-item-label {
        text-align: left;
        text-transform: capitalize;
      }
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 10px;
  }

  .ant-btn.upload-image, .ant-upload.ant-upload-drag.dragger {
    svg {
      @include styles.mixins-style-width-height(25px, 25px);
    }
  }
}

.crop-image {
  .ant-modal-content {
    .reactEasyCrop_Container {
      background-color: styles.$variables-primary-light-blue;
    }
    .img-crop-control.img-crop-control-zoom {
      color: styles.$variables-primary-blue;
      font-weight: 700;

      .ant-slider-handle {
        @include styles.mixins-style-border(solid, 3px, styles.$variables-primary-blue, 50%);
      }
      .ant-slider-track{
        background-color: styles.$variables-primary-blue;
      }
    }
    .ant-btn-primary {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-primary-blue, 20px);
      background: styles.$variables-primary-blue;
    }
    .ant-btn-default {
      @include styles.mixins-style-border(solid, 1px, styles.$variables-dark-grey, 20px);
      background: styles.$variables-white;
    }
  }
}
