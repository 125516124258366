@use '../../../styles';

.card-container {
  margin-bottom: 20px;

  .card {
    border-radius: 16px;
    overflow: hidden;

    &.card-pointer {
      cursor: pointer;
    }
  }

  .service-inactive {
    opacity: 50%;
  }


  &__image-container {
    @include styles.mixins-style-width-height(100%, 100%);
  }

  .ant-card {
    @include styles.mixins-style-border(solid, 1px, styles.$variables-extended-light-blue, 16px);
    background: styles.$variables-white;
    box-shadow: styles.$variables-vertical-box-shadow;
    margin-bottom: 20px;

    .ant-card-body {
      padding: 16px;

      .description-container {
        .description-title {
          font-weight: bold;
        }
      }
    }

    .ant-card-bordered .ant-card-cover {
      margin: 0;
    }

    .ant-card-cover {
      @include styles.mixins-style-width-height(100%, 200px);

      .card-logo {
        @include styles.mixins-style-width-height(100%, 100%);
          object-fit: contain;
          padding: 16px;

          &.card-logo-cover {
            object-fit: cover;
          }
        }

        .empty-container {
          padding: 16px;

          .empty-image  {
            @include styles.mixins-style-flex-center;
            @include styles.mixins-style-width-height(100%, 168px);
            background-color: styles.$variables-light-blue-3;

            svg {
              @include styles.mixins-style-width-height(32px, 32px);

              path {
                color: styles.$variables-white;
              }
            }
          }
        }
      }

      .title {
        @include styles.mixins-style-font-format(22px, 28px, 900, capitalize);
        @include styles.mixins-style-text-truncate;
        overflow: unset;
        text-align: left;
        color: styles.$variables-dark-grey;
      }

      .subtitle {
        @include styles.mixins-style-font-format(14px, 20px, 700);
        @include styles.mixins-style-text-truncate;
        margin-top: -20px;
      }

      .buttons-section {
        @include styles.mixins-style-flex-row-space-between;
        gap: 8px;

        .secondary-delete-button,
        .secondary-button {
          width: 50%;
        }

        .ant-btn.primary-button.md, .ant-btn.secondary-delete-button.md{
          width: calc(50% - 8px);
        }

        .ant-btn.primary-button.md.requested-service-button:disabled {
          background: styles.$variables-dark-blue;
          border-color: styles.$variables-dark-blue;
          color: styles.$variables-white;
        }

        .ant-btn.primary-button.md:disabled {
          background: styles.$variables-secondary-grey;
          border-color: styles.$variables-primary-grey;
          color: styles.$variables-primary-grey;
        }
      }

      .description-container {
        flex: 3;
        min-height: 120px;
      }
      .category-container {
        @include styles.mixins-style-flex-column-start;
        text-align: left;

        .description-title {
          @include styles.mixins-style-font-format(14px, 20px, 700, capitalize);
          color: styles.$variables-dark-grey;
          margin-bottom: 10px;
        }

        :not(.rounded) {
          .category-section {
            .description {
              @include styles.mixins-style-font-format(14px, 20px, 400);
              color: styles.$variables-dark-grey;
            }
          }
        }
      }

      .category-container {
        .rounded {
          color: styles.$variables-dark-blue-1;

          .category-section {
            width: 100%;
            flex-direction: row;
            display: flex;
          }
        }
      }
  }
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm {
  @include styles.mixins-style-width-height(30% !important, 10vh !important);
  top: 25%;
  max-width: 100%;

  .ant-modal-body {
    height: fit-content !important;

    .ant-modal-confirm-body {
      .anticon.anticon-exclamation-circle {
        color: styles.$variables-primary-error;
      }
    }

    .ant-btn.ant-btn-default {
      @include styles.mixins-style-font-format(14px, 20px, 700);
      border-radius: 16px;
    }

    .ant-btn.ant-btn-default.ant-btn-dangerous {
      color: styles.$variables-primary-error;
    }

    .ant-btn.ant-btn-default :hover :active {
      background: styles.$variables-white;
    }
  }
}
